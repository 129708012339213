body {
  background-color: white; 
  text-align: center; 
  padding: 0; 
  font-family: Helvetica, Arial, sans-serif;
  
}
#container {
margin: 0;
position: absolute;
top: 50%;
left: 50%;
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}

@media only screen and (min-width: 601px) {
h1   {
  color: #00617a; 
  font-size: 100px;
        transition: 0.8s;
}
h1:hover {
  opacity: 0.6;
}
p    {
  color: #999; 
  font-size: 1.2em;
}
}

@media only screen and (max-width: 600px) {
h1   {
  color: #00617a; 
  font-size: 60px;
  
}
p    {
  color: #999; 
  font-size: 1.2em;
}
}
